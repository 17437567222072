// This helper function will allows us to get the domain name regardless of its form
// beta.example.com => example.com
// example.com/* => example.com

const NEXTAUTH_URL = 'https://selfpublishingtitans.com';
// const NEXTAUTH_URL = 'http://localhost:3000';

// Define how we want the session token to be stored in our browser

export const getTokenFromNextAuth = async (): Promise<string | null> => {
  try {
    // get the base url without the sobdomain
    const authTokenEncrypted = await fetch(`${NEXTAUTH_URL}/api/auth/session`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }).then((res) => res.json());
    // console.log('authTokenEncrypted: ', authTokenEncrypted);

    if (authTokenEncrypted) {
      // const { NEXT_PUBLIC_NEXTAUTH_SECRET } = process.env;
      // if (!NEXT_PUBLIC_NEXTAUTH_SECRET) {
      //   throw new Error('Missing NEXT_PUBLIC_NEXTAUTH_SECRET');
      // }

      // const isNextAuthRequest = true; // Simplified for now
      // if (isNextAuthRequest && authTokenEncrypted) {
      //   const res: any = await decode({
      //     token: authTokenEncrypted,
      //     secret: NEXT_PUBLIC_NEXTAUTH_SECRET,
      //   });
      //   console.log('res: ', res);
      //   return (res?.token as string) || null;
      // }
      return authTokenEncrypted.token;
    }
  } catch (error) {
    console.error('Error getting token from NextAuth:', error);
  }

  return null;
};

export const getUserInfo = async (): Promise<any> => {
  try {
    // get the base url without the sobdomain
    const authTokenEncrypted = await fetch(`${NEXTAUTH_URL}/api/auth/session`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }).then((res) => res.json());
    // console.log('authTokenEncrypted: ', authTokenEncrypted);

    if (authTokenEncrypted) {
      // const { NEXT_PUBLIC_NEXTAUTH_SECRET } = process.env;
      // if (!NEXT_PUBLIC_NEXTAUTH_SECRET) {
      //   throw new Error('Missing NEXT_PUBLIC_NEXTAUTH_SECRET');
      // }

      // const isNextAuthRequest = true; // Simplified for now
      // if (isNextAuthRequest && authTokenEncrypted) {
      //   const res: any = await decode({
      //     token: authTokenEncrypted,
      //     secret: NEXT_PUBLIC_NEXTAUTH_SECRET,
      //   });
      //   console.log('res: ', res);
      //   return (res?.token as string) || null;
      // }
      return authTokenEncrypted;
    }
  } catch (error) {
    console.error('Error getting token from NextAuth:', error);
  }

  return null;
};

const ProxyTokenUrl = 'https://go.selfpublishingtitans.com/api/v1/answer/token';

export async function getCommunityToken() {
  const mainSiteToken = await getTokenFromNextAuth();
  if (!mainSiteToken) {
    return null;
  }

  // console.log('mainSiteToken: ', mainSiteToken);

  const response = await fetch(ProxyTokenUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${mainSiteToken}`,
    },
  });

  const data: User = await response.json();
  return data.access_token;
}

export interface User {
  id: string;
  created_at: number;
  last_login_date: number;
  username: string;
  e_mail: string;
  mail_status: number;
  notice_status: number;
  follow_count: number;
  answer_count: number;
  question_count: number;
  rank: number;
  authority_group: number;
  display_name: string;
  avatar: string;
  mobile: string;
  bio: string;
  bio_html: string;
  website: string;
  location: string;
  language: string;
  color_scheme: string;
  access_token: string;
  role_id: number;
  status: string;
  have_password: boolean;
  visit_token: string;
}
